import { Outlet } from "react-router-dom";
import Nav from './navbar'

export default function Content() {
    return (
      <>
      <Nav/>
        <div className="pt-5 mt-5">
                <div className="container">
                    <Outlet />
                </div>
            </div>
      </>
    );
}