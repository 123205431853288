import { useEffect } from "react"
import {Navigate, Outlet, useNavigate} from "react-router-dom"
import api from "../services/api"

const useAuth = () => {
	//get item from localstorage

	let user: any

	const _user = localStorage.getItem("token")



	if (_user) {
		user = JSON.parse(_user)
		// console.log("user", user, user.token.token)
        api.defaults.headers.common['Authorization'] = `Bearer ${user.token.token}`;
	}
	if (user) {
		return {
			auth: true,
			role: null
			// role: user.role,
		}
	} else {
		return {
			auth: false,
			role: null,
		}
	}
}

//protected Route state
type ProtectedRouteType = {
	roleRequired?: "ADMIN" | "USER"
}

const ProtectedRoutes = (props: ProtectedRouteType) => {
	const {auth, role} = useAuth();
	const navigate = useNavigate();
	let incomplete = ''

	useEffect(() => {
        if(!localStorage.getItem('token')){
            return navigate("/login");
        }

			if(window.location.pathname == "/"){
				return navigate("/home");
			}
		
		incomplete = JSON.parse(localStorage.getItem('token') || "").user.incomplete_registration
    }, [])


    // console.log(incomplete, ' pojkpjk')
	//if the role required is there or not
	// if (props.roleRequired) {
	// 	return auth ? (
	// 		props.roleRequired === role ? (
	// 			<Outlet />
	// 		) : (
	// 			<Navigate to="/denied" />
	// 		)
	// 	) : (
	// 		<Navigate to="/login" />
	// 	)
	// } else {
					//(incomplete ? <Navigate to="/profile" /> : <Navigate to="/login" />)               && !incomplete
		return (
			<>
                    {/*<Navigate to="/profile" />*/}
					{/* {auth ? incomplete ? <Navigate to="/profile" /> : <Outlet /> : <Navigate to="/profile" />}*/}
                    {/*window.location.pathname == '/profile'*/}
                    {/*     // (incomplete && (window.location.pathname != '/profile') ?  <Navigate to="/profile" /> : <Outlet />) :*/}
					 {auth ?
                          <Outlet /> :
                         <Navigate to="/login" />
					 }
            </>
			)
	// }
}

export default ProtectedRoutes