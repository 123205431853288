import {useEffect, useState} from 'react'
import api from '../../services/api'
import {useNavigate} from 'react-router'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import {useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import { SelectTheme } from '../../components/selectTheme'


export default function FormTeam() {
    const navigate = useNavigate()

    let {id} = useParams()

    const [name, setName] = useState('')
    const [active, setActive] = useState(new Date().toLocaleString('pt-BR'))
    const [phone, setPhone] = useState('' as any)
    const [club, setClub] = useState('' as any)

    const [arrayClubs, setArrayClubs] = useState([] as any)

    const objReg: any = {
        "id": id,
        "name": name,
        "active": active,
        "phone": phone,
        "image": '',
        "club_id": club,
    }

    useEffect(() => {
        clubs()

        if (id != undefined) {
            api.get('/api/teams/' + id)
                .then(resposta => {
                    const team = resposta.data.data

                    setActive(team?.active)
                    setName(team?.name)
                    setPhone(team?.phone)
                    setClub(team?.club_id)
                })
        }

    }, [])

    async function clubs(value = '') {
        const response = await api.get(`/api/clubs`)
        const result = await response.data
        return setArrayClubs(
            await result.data.map((club) => ({
                label: club.fantasia,
                value: club.id,
            })),
        )
    }

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            if (id == undefined) {
                // api.post('/api/athletes', objReg).then((result) => {
                //   console.log(result)
                //   Swal.fire({
                //     title: 'Sucesso!',
                //     text: result.data.message,
                //     icon: 'success',
                //     timer: 5000
                //   }).then((result) => {
                //     // if(!formReduzido){
                //     //   return navigate("/login");
                //     // }else{
                //     //   resetaForm();
                //     // }
                //   });
                // })
                //   .catch(erro => {
                //       console.log(erro.response)
                //       Swal.fire({
                //         title: 'Erro!',
                //         text: erro.response.data.sqlMessage,
                //         icon: 'error',
                //       })
                //     }
                //   );


            } else {
                api.put('/api/teams/' + id, objReg).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                    }).then((result) => {
                        // return navigate("/adm/athletes");
                    });
                })
                    .catch(erro => Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.sqlMessage,
                        icon: 'error',
                    }))
            }
        } catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className='text-left'>

                <div className='form-row'>
                    <div className='form-group col-md-4'>
                        <label htmlFor='active'>Ativo <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Ativo', value: '1'}}
                            options={[{label: 'Ativo', value: '1'}, {label: 'Inativo', value: '0'}]}
                            value={[{label: 'Ativo', value: '1'}, {
                                label: 'Inativo',
                                value: '0',
                            }].find(option => option.value == active)}
                            name='active'
                            id='active'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setActive(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>
                </div>

                <div className='form-row'>
                    <div className='form-group col-md-12'>
                        <label htmlFor='name'>Nome da equipe <i className="text-danger"> *</i></label>
                        <input onChange={evento => setName(evento.target.value)} type='text' className='form-control'
                               value={name}
                               id='name' placeholder='Nome da equipe' />
                    </div>

                    <div className='form-group col-md-6'>
                        <label htmlFor='phone'>Celular <i className="text-danger"> *</i></label>
                        <InputMask mask='(99) 99999-9999' onChange={evento => setPhone(evento.target.value)} type='text'
                                   className='form-control'
                                   value={phone} id='phone' placeholder='Celular do capitão' />
                    </div>

                    <div className='form-group col-md-6'>
                        <label htmlFor='club'>Clube <i className="text-danger"> *</i></label>

                        <Select
                            options={arrayClubs}
                            name='club'
                            id='club'
                            onChange={(data: any) => {
                                setClub(data.value)
                            }}
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            value={arrayClubs.find(option => option.value == club) || ''}
                            placeholder='Seu clube'
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>
                </div>

                <div className='row py-4'>
                    <div className='col-md-12  text-right'>
                        <button type='button' className='btn btn-danger mr-1' onClick={() => navigate(-1)}>Cancelar<br/> </button>
                        <button type='submit' className='btn btn-success'> Salvar</button>
                    </div>
                </div>
            </form>

        </>

    )
}