import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router'
import api from '../../services/api'
import Swal from "sweetalert2";

export default function GeneralResult() {
    const {id} = useParams()
    const navigate = useNavigate()

  const [title, setTitle] = useState<any>('')

    const [results, setResults] = useState<any>([])
    const [resultsCategories, setResultsCategories] = useState<any>({})
    const [teamsResults, setTeamsResults] = useState<any>([])

    const [tests, setTests] = useState<any>([])
    const [categories, setCategories] = useState<any>([])
    const [teams, setTeams] = useState<any>([])

    const userLogado = JSON.parse(localStorage.getItem('token') || '').user
    const athleteLogado = JSON.parse(localStorage.getItem('token') || '').user.athlete?.id
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await api.get('/api/getGeneralResults/' + id)
                  .then(resposta => {
                      setTitle(resposta.data.title)
                      setResults(resposta.data.results)
                      setTests(resposta.data.tests)

                      setTeamsResults(resposta.data.result_team)

                      setTeams(resposta.data.teams)
                      setCategories(resposta.data.categories)

                      for (const categoria of resposta.data.categories) {
                          const resultadosDaCategoria = resposta.data.results.filter(resultado => resultado.category_id === categoria.id)
                          setResultsCategories(prevResultsCati => ({
                              ...prevResultsCati,
                              [categoria.id]: resultadosDaCategoria,
                          }))
                      }

                  }).then(resposta =>
                    setLoading(false)
                  )
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false);
            }
        }

        fetchData()
        //   .then(() => {
        //     // Re-render component after fetchData is finished
        // });

    }, [])

    const reprocessResults = (competition_id: number) => {
        api.post('/api/competitions/reprocessResults/' + competition_id)
            .then(resposta => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: "Resultados reprocessados com sucesso",
                    icon: 'success',
                    timer: 3000
                }).then(response => window.location.reload());
            }).catch(erro => {
                Swal.fire({
                    title: 'Erro!',
                    text: erro.response.data.sqlMessage,
                    icon: 'error',
                    timer: 5000
                });
            }
        );
    }

    const getTeamName = (id) => {
        const team = teams.filter(timess => timess.id == id )
        return team[0].team.fantasia
    }

    return (
      <>
          {!loading ? (
              <>
                  <div className='row'>
                      <div className='col-md-12'>
                          <h3 className='mb-2'>
                            <b>{ title }</b>
                          </h3>
                          {userLogado?.role == 'admin' ? (
                            <button type='button' className='btn btn-warning ml-2' onClick={() => reprocessResults(3)}>Reprocessar resultados</button>
                          ) : null}
                      </div>
                  </div>

                  <ul className='nav nav-tabs justify-content-center' role='tablist'>
                      <li className='nav-item'>
                          <a className='nav-link active' data-toggle='tab' href='#generalResult'>Resultado Geral</a>
                      </li>
                      <li className='nav-item'>
                          <a className='nav-link' data-toggle='tab' href='#resultForCategory'>Resultado por
                              Categoria</a>
                      </li>
                      <li className='nav-item'>
                          <a className='nav-link' data-toggle='tab' href='#resultForTeam'>Resultado por Equipe</a>
                      </li>
                  </ul>

                <p className={'text-danger font-weight-bold mt-3'}> * Pontuações em vermelho significam descarte</p>

                  <div className='tab-content'>
                      <div id='generalResult' className='container tab-pane active'><br/>
                          <div className='accordion' id='accordionExample'>
                              <div className='card'>
                                  <div className='card-header' id='headingOne'>
                                      <h2 className='mb-0'>
                                          <button className='btn btn-link btn-block text-left' type='button'
                                                  data-toggle='collapse'
                                                  data-target='#collapseOne' aria-expanded='true'
                                                  aria-controls='collapseOne'>
                                              <b>Resultado Geral</b>
                                          </button>
                                      </h2>
                                  </div>

                                  <div id='collapseOne' className='collapse show' aria-labelledby='headingOne'
                                       data-parent='#accordionExample'>
                                      <div className='card-body'>
                                          <table className='table table-striped table-hover'>
                                              <thead>
                                              <tr>
                                                  <th scope='col'>Posição</th>
                                                  <th scope='col'>Nome</th>
                                                  {/*<th scope='col'>eq</th>*/}
                                                  {tests.map((res, index) =>
                                                    <th key={`${index}`} title={`${res.description}`} scope='col'>{res.final == "0" ?`${index+1}ª E`: "Final"}</th>
                                                  )}

                                                  <th scope='col'>Total</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {results != undefined && results.length > 0 ?
                                                (<>
                                                      {results.map((res, index) =>
                                                            <tr key={`geral-${index}`}
                                                                className={athleteLogado == res.athlete_id ? 'table-warning' : ''}
                                                                data-toggle='collapse'
                                                                data-target={`#collapse0_${index}`}
                                                                aria-expanded='false'
                                                                aria-controls={`collapse0_${index}`}>
                                                                <th scope='row'> {index + 1 <= 9 ? ` ` : ''} {index + 1}</th>
                                                                <td>{res.athlete.full_name}</td>
                                                                {/*<td>{teams[res.athlete.team_id -1]?.fantasia}</td>*/}
                                                                {res.resul.map((resu, index) =>
                                                                  <td key={`${index}`} className={res.menorValor.indi == index ? 'text-danger font-weight-bold' : ''} >{resu.resultTotalSeries}</td>
                                                                )}
                                                                <th title={`${!res.motivoDesemp ? '':`Motivo desempate: ${res.motivoDesemp}\nReinscrições: ${res.subTotal}\nData Nasc: ${res.athlete.birth_date_formated}`}`} >{res.resultGeneralTotal}
                                                                  {/*&nbsp; <i  className="fa fa-info-circle" aria-hidden="true"></i>*/}
                                                                </th>

                                                            </tr>
                                                          )}
                                                  </>
                                                ) :
                                                (<tr>
                                                      <td colSpan={3 + tests.length} className='text-center'>
                                                          Nenhum resultado lançado
                                                      </td>
                                                  </tr>
                                                )}

                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div id='resultForCategory' className='container tab-pane fade'><br />
                          <div className='accordion' id='accordionCategory'>

                              {categories.map((category, index) =>
                                <div key={`${index}`} className='card'>
                                    <div className='card-header' id={`headingCategory-${category.description}`}>
                                        <h2 className='mb-0'>
                                            <button className='btn btn-link btn-block text-left collapsed' type='button' data-toggle='collapse'
                                                    data-target={`#collapseCategory-${category.description}`} aria-expanded='false'
                                                    aria-controls={`collapseCategory-${category.description}`}>
                                                <b>Categoria {category.description}</b>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id={`collapseCategory-${category.description}`}
                                         className={index == 0 ? 'collapse show' : 'collapse'}
                                         aria-labelledby={`headingCategory-${category.description}`} data-parent='#accordionCategory'>
                                        <div className='card-body'>
                                            <table className='table table-striped table-hover'>
                                                <thead>
                                                <tr>
                                                    <th scope='col'>Posição</th>
                                                    <th scope='col'>Nome</th>
                                                    {tests.map((res, index) =>
                                                      <th key={`${index}`} title={`${res.description}`} scope='col'>{res.final == "0" ?`${index+1}ª E`: "Final"}</th>
                                                    )}

                                                    <th scope='col'>Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {resultsCategories[category.id] != undefined && resultsCategories[category.id].length > 0 ?
                                                  (<>
                                                        {resultsCategories[category.id].map((res, index) =>
                                                          <tr key={`categories-${index}`} className={athleteLogado == res.athlete_id ? 'table-warning' : ''}>
                                                              <th scope='row'> {index + 1 <= 9 ? ` ` : ''} {index + 1}</th>
                                                              <td>{res.athlete.full_name}</td>
                                                              {res.resul.map((resu, index) =>
                                                                <td key={`${index}`} className={res.menorValor.indi == index ? 'text-danger font-weight-bold' : ''} >{resu.resultTotalSeries}</td>
                                                              )}
                                                              <th title={`${!res.motivoDesemp ? '':`Motivo desempate: ${res.motivoDesemp}\nReinscrições: ${res.subTotal}\nData Nasc: ${res.athlete.birth_date_formated}`}`} >{res.resultGeneralTotal}</th>
                                                          </tr>
                                                        )}
                                                    </>
                                                  ) :
                                                  (<tr>
                                                        <td colSpan={3 + tests.length} className='text-center'>
                                                            Nenhum resultado lançado
                                                        </td>
                                                    </tr>
                                                  )}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>,
                              )}

                          </div>
                      </div>

                      <div id='resultForTeam' className='container tab-pane fade'><br />
                          <div className='accordion' id='accordionFromTeam'>
                              {teamsResults.map((team, index) =>
                                <div key={`${index}`} className='card'>
                                    <div className='card-header' id={`headingTeam-${index}`}>
                                        <h2 className='mb-0'>
                                            <button className='btn btn-link btn-block text-left collapsed' type='button'
                                                    data-toggle='collapse' data-target={`#collapseTeam-${index}`} aria-expanded='false'
                                                    aria-controls={`collapseTeam-${index}`}>
                                                <b>{getTeamName(team.team_id) }
                                                    <span title={`${!team.motivoDesemp ? '':`Motivo desempate: ${team.motivoDesemp}\n`}Soma das etapas: ${team.count} \nInscrições: ${team.registrations}\nReinscrições: ${team.reRegistrations}`}> &nbsp;{team.count}  &nbsp;</span>
                                                </b>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id={`collapseTeam-${index}`} className={index == 0 ? 'collapse show' : 'collapse'}
                                         aria-labelledby={`headingTeam-${index}`}
                                         data-parent='#accordionFromTeam'>
                                        <div className='card-body'>
                                            <table className='table table-striped'>
                                                <thead>
                                                <tr>
                                                    <th scope='col'> </th>
                                                    {tests.map((res, index) =>
                                                      <th key={`${index}`} title={`${res.description}`} scope='col'>{res.final == "0" ?`${index+1}ª E`: "Final"}</th>
                                                    )}
                                                    <th scope='col'>Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {team.results != undefined && team.results.length > 0 ?
                                                      (<>
                                                          <tr key={`teams-${index}`}>
                                                              <th scope='row'>&nbsp;</th>
                                                              {team.results.map((result, index) =>
                                                                <td key={`${index}`} className={team.menorVa.indi == index ? 'text-danger font-weight-bold' : ''} >{result.count}</td>
                                                              )}
                                                              <th>{team.count}</th>
                                                          </tr>
                                                          <tr key={`text-teams-registrations-${index}`}>
                                                            <th scope='row'>&nbsp;</th>
                                                            <th scope='row' colSpan={tests.length}>Número de atiradores</th>
                                                          </tr>
                                                          <tr key={`teams-registrations-${index}`}>
                                                            <th scope='row'>&nbsp;</th>
                                                            {team.results.map((result,index) =>
                                                                <td key={`${index}`} className={team.menorVa.indi == index ? 'text-danger font-weight-bold' : ''}>{result.registrations}</td>
                                                            )}
                                                            <th>{team.registrations}</th>
                                                          </tr>
                                                        </>) :
                                                      (<tr>
                                                            <td colSpan={3 + tests.length} className='text-center'>
                                                                Nenhum resultado lançado
                                                            </td>
                                                        </tr>
                                                      )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                              )}
                          </div>
                      </div>
                  </div>

                  <div className='row py-4'>
                      <div className='col-md-12  text-right'>
                          <button className='btn btn-danger btn-floating' onClick={() => navigate(-1)}>Voltar<br/>
                          </button>
                      </div>
                  </div>
              </>
            )
            : (<div className="loading">
                <span className="loader"></span>
            </div>)
          }
      </>

    )
}