import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../services/api'
import Swal from 'sweetalert2'

export default function ForgotPasswordForm() {
  const navigate = useNavigate()


  const [email, setEmail] = useState('')
  const [emailEnviado, setEmailEnviado] = useState(false)

  useEffect(() => {
  }, [])

  const forgot = (evento: React.FormEvent<HTMLFormElement>) => {
    evento.preventDefault()
    api.post('/api/forgot-password', {
      email: email,
      resetPasswordUrl: 'https://campeonatointerclubes.com.br/resetPassword/',
    }).then((result) => {
      // console.log(result)
      Swal.fire({
        title: 'Sucesso!',
        text: result.data.message,
        icon: 'success',
        timer: 5000,
      }).then((result) => {
        setEmailEnviado(true)
        // if(!formReduzido){
        //   return navigate("/login");
        // }else{
        //   resetaForm();
        // }
      })
    }).catch(erro => {
        console.log(erro.response)
        Swal.fire({
          title: 'Erro!',
          text: erro.response.data.sqlMessage,
          icon: 'error',
        })
      },
    )
  }


  return (

    <>

      {!emailEnviado ? (

          <form onSubmit={forgot} autoComplete='off' className='text-left'>

            <h5>Se você esqueceu sua senha, insira o seu email enviaremos um link para você voltar a acessar a sua conta.</h5>

            <div className='form-group'>
              <label htmlFor='email'>Infome seu e-mail</label>
              <input onChange={evento => setEmail(evento.target.value)} type='email' className='form-control'
                     value={email}
                     id='email' placeholder='Seu email' />
            </div>

            <button type='submit' className='btn btn-primary btn-block mb-4'>
              Continuar
            </button>
          </form>
        ) :
        (
          <>
            <h4>E-mail confirmado com sucesso, para finalizar a troca da sua senha clique no link enviado para o seu e-mail.</h4>
          </>
        )


      }
    </>
  )
}