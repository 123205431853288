import {Navigate, Outlet, useNavigate} from "react-router";
import axios from "axios";
import Swal from "sweetalert2";
import { useEffect, useState } from 'react'

export default function Nav() {
    const navigate = useNavigate();

    const [athleteLogado, setAthleteLogado] = useState("");
    const [user, setUser] = useState({}  as any);

    useEffect(() => {
        if(!localStorage.getItem('token')){
            return navigate("/login");
        }
        setAthleteLogado(JSON.parse(localStorage.getItem('token') || "").user.athlete?.full_name)
        setUser(JSON.parse(localStorage.getItem('token') || "").user)
    }, [])

    const logout = () => {
        axios.post(`${process.env.REACT_APP_API_HOST}/api/logout`)
            .then((result) => {
                let res = result.data;
                // console.log(res)
                localStorage.removeItem('token');
                return navigate("/login");
            })
            .catch(erro => Swal.fire({
                title: 'Erro!',
                text: 'Erro ao sair',
                icon: 'error',
            }))

    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-primary">
            <div className="container">
                <a style={{ fontWeight: 900 }} className="navbar-brand" title="Campeonato Regional Interclubes" data-placement="bottom" data-toggle="tooltip" href="/home">INTERCLUBES</a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNowUIKitFree">
                    {/* <i className="fa fa-user-circle-o" aria-hidden="true"></i> */}
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse text-center justify-content-end" id="navbarNowUIKitFree">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-header"><h6 className="dropdown-header">{user?.athlete?.full_name}</h6></a>
                                    {/*{user?.role == 'athlete' ?*/}
                                    {/*    <>*/}
                                    {/*        <a className="dropdown-item" href="/profile"><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; Perfil</a>*/}
                                    {/*        <a className="dropdown-item" href="/weapons"><i className="fa fa-crosshairs fa-fw" aria-hidden="true"></i>&nbsp; Meu arsenal</a>*/}
                                    {/*    </>*/}
                                    {/*:null*/}
                                    {/*}*/}

                                    {user?.organizer === "1" && user?.role == 'admin' ?
                                        <>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item" href="/adm/teams"><i className="fa  fa-users fa-fw" aria-hidden="true"></i>&nbsp; Equipes</a>
                                            <a className="dropdown-item" href="/adm/athletes"><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; Atlhetas</a>
                                            <a className="dropdown-item" href="/adm/competitions"><i className="fa fa-list-alt fa-fw" aria-hidden="true"></i>&nbsp; Competições</a>
                                            <a className="dropdown-item" href="/adm/tests"><i className="fa fa-flag-checkered fa-fw" aria-hidden="true"></i>&nbsp; Provas</a>
                                        </>
                                        :null
                                    }
                                <div className="dropdown-divider"></div>
                                {/*<a className="dropdown-item" href="/changePassword"><i className="fa fa-user fa-key" aria-hidden="true"></i>&nbsp; Alterar Senha</a>*/}
                                <button className="dropdown-item" onClick={logout}><i className="fa fa-power-off fa-fw" aria-hidden="true"></i>&nbsp; Sair</button>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    );
}

