export function formatDa(data: any, tipo: string) {
  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  function convertDateForIos(date) {
    var arr = date.split(/[- : T Z]/);
    date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return date;
  }

  let dataAtual = (window.navigator.userAgent.indexOf("Mac") != -1) ? convertDateForIos(data) : new Date(data);
  // console.log(data)

  if (tipo == 'data') {
    let day = ''
    switch (dataAtual.getDay()) {
      case 0:
        day = "Domingo";
        break;
      case 1:
        day = "Segunda-feira";
        break;
      case 2:
        day = "Terça-feira";
        break;
      case 3:
        day = "Quarta-feira";
        break;
      case 4:
        day = "Quinta-feira";
        break;
      case 5:
        day = "Sexta-feira";
        break;
      case 6:
        day = "Sábado";
    }

    let dataAtualFormatada = (day + " " + adicionaZero(dataAtual.getDate().toString()) + "/" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "/" + dataAtual.getFullYear().toString().substring(2));
    return dataAtualFormatada
  }

  if (tipo == 'hour') {
    let horaFormatada = (adicionaZero(dataAtual.getHours().toString()) + ":" + (adicionaZero(dataAtual.getMinutes()).toString()));
    return horaFormatada
  }
}

export function validaCPF(strCPF: any) {
  var soma;
  var resto;
  soma = 0;

  strCPF = strCPF.replaceAll('.', '').replaceAll('-', '');

  if (strCPF == "00000000000") return false;

  for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if ((resto == 10) || (resto == 11)) resto = 0;
  if (resto != parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if ((resto == 10) || (resto == 11)) resto = 0;
  if (resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}