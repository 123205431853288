import axios from "axios"
import {useEffect, useState} from "react"
import Async from 'react-select/async';
import Select from "react-select";
import api from "../../services/api";

import Swal from 'sweetalert2'
import InputMask from 'react-input-mask';
import { useNavigate } from "react-router-dom";
import { validaCPF } from "../../assets/js/helper";
import { SelectTheme } from '../../components/selectTheme'

export default function RegisterForm({formReduzido = false, station= 0 }) {
    const navigate = useNavigate();

    if(!formReduzido){
        localStorage.removeItem('token');
    }

    let authUser = (localStorage.getItem('token') || undefined) as any
    authUser = (authUser != undefined ? JSON.parse(authUser) : undefined)
    const id = (authUser != undefined && authUser.user.role == 'athlete' && !formReduzido ? authUser.user?.id : undefined)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const [fullName, setFullName] = useState('');
    const [cpf, setCpf] = useState('');
    const [phone, setPhone] = useState('');
    const [genre, setGenre] = useState("M" as any);
    const [birthDate, setBirthDate] = useState('');
    const [team, setTeam] = useState('');
    const [category, setCategory] = useState('4' as any);
    const [level, setLevel] = useState('1' as any);

    const [zipCode, setZipCode] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [complement, setComplement] = useState('');
    const [city, setCity] = useState({} as any);

    const [arrayTeams, setArrayTeams] = useState([] as any);
    const [arrayCategories, setArrayCategories] = useState([] as any);

    const [user_incomplete_registration, setUserIncompleteRegistration] = useState(0);

    useEffect(() => {
        teams();
        categories();
        if (id != undefined) {
            api.get('/api/users/' + id)
                .then(resposta => {
                    const user = resposta.data.data;

                    setEmail(user.email);
                    setUserIncompleteRegistration(user.incomplete_registration);
                    // console.log(user.incomplete_registration, 'db incomplete')

                    setFullName(user.athlete?.full_name);
                    setCpf(user.athlete?.cpf);
                    setPhone(user.athlete?.phone);
                    setBirthDate(new Date(user.athlete?.birth_date).toLocaleString("pt-BR"));
                    setGenre(user.athlete?.genre);
                    setCategory(user.athlete?.category_id);
                    setTeam(user.athlete?.team_id);

                    setZipCode(user.athlete?.address.zip_code);
                    setStreet(user.athlete?.address.street);
                    setNumber(user.athlete?.address.number);
                    setDistrict(user.athlete?.address.district);
                    setComplement(user.athlete?.address.complement);
                    setCity({
                        value: user.athlete?.address.city.id,
                        label: user.athlete?.address.city.name + ' - ' + user.athlete?.address.city.state.abbreviation
                    })
                })

        }
    }, []);

    const objReg = {
        auth: authUser,
        user: {
            email: email,
            password: password,
            username: fullName.split(' ')[0],
            avatar: '',
            incomplete_registration: (formReduzido ? true : false)
        },
        atlethe: {
            name: fullName,
            cpf: cpf,
            genre: genre,
            phone: phone,
            birthDate: (birthDate.substr(6, 4) + '-' + birthDate.substr(3, 2) + '-' + birthDate.substr(0, 2)),
            category_id: category,
            level_id: level,
            club: team,
            address: {
                zipCode: zipCode,
                street: street,
                number: number,
                district: district,
                complement: complement,
                city_id: city.value,
            }
        },
        formReduzido: formReduzido
    };

    const validadeRegister = () => {
        if (!email) {
            throw "E-mail deve ser informado";
        }

        if(!formReduzido){
            if (!password) {
                throw "Senha deve ser informado";
            }
            
            if (!passwordConfirmation) {
                throw "Confimação da senha deve ser informado";
            }
        }

        if (!fullName) {
            throw "Nome completo deve ser informado";
        }

        if (!cpf) {
            throw "CPF deve ser informado";
        }
        
        if(!validaCPF(cpf)){
            throw "CPF inválido";
        }

        if (!phone) {
            throw "Celular deve ser informado";
        }

        if (!category) {
            throw "Ccategoria deve ser selecionada";
        }

        if (!team) {
            throw "Equipe deve ser selecionada";
        }

        if (!birthDate) {
            throw "Data de nascimento deve ser informada";
        }

        // if(!formReduzido){
        //     if (!zipCode) {
        //         throw "Cep deve ser informado";
        //     }
        //
        //     if (!street) {
        //         throw "Rua deve ser informada";
        //     }
        //
        //     if (!district) {
        //         throw "Bairro deve ser informado";
        //     }
        //
        //     if (!city.value) {
        //         throw "Cidade deve ser selecionada";
        //     }
        // }
    };

    const resetaForm = () => {
        setEmail('');
        setPassword('');
        setPasswordConfirmation('');
    
        setFullName('');
        setCpf('');
        setPhone('');
        setGenre("M");
        setBirthDate('');
        setTeam('');
        setCategory('4');
        setLevel("1");

        setZipCode('');
        setStreet('');
        setNumber('');
        setDistrict('');
        setComplement('');
        setCity({});
    }

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            validadeRegister();

            if (id == undefined) {
                if (password == passwordConfirmation) {
                    api.post('/api/users', objReg).then((result) => {
                        if (station != 0){
                            // console.log(result)
                            const input = document.getElementById('collapseAthletes' + station) as HTMLInputElement | null;
                            input?.classList.remove("show")
                        }
                        Swal.fire({
                            title: 'Sucesso!',
                            text: result.data.message,
                            icon: 'success',
                            timer: 5000
                        }).then((result) => {
                            if(!formReduzido){
                                return navigate("/login");
                            }else{
                                resetaForm();
                            }
                        });
                    })
                        .catch(erro => {
                                console.log(erro.response)
                                Swal.fire({
                                    title: 'Erro!',
                                    text: erro.response.data.sqlMessage,
                                    icon: 'error',
                                })
                            }
                        );

                } else {
                    Swal.fire({
                        title: 'Erro!',
                        text: 'Senhas devem ser iguais!',
                        icon: 'error',
                    })
                }
            } else {
                // api.put('/api/users/' + id, objReg).then((result) => {
                //     Swal.fire({
                //         title: 'Sucesso!',
                //         text: result.data,
                //         icon: 'success',
                //     })
                // })
                //     .catch(erro => Swal.fire({
                //         title: 'Erro!',
                //         text: erro,
                //         icon: 'error',
                //     }))
            }
        } catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }
    };

    async function teams(value = '') {
        const response = await api.get(`/api/teams`)
        const result = await response.data
        return setArrayTeams(
            await result.data.map((club) => ({
                label: club.fantasia,
                value: club.id
            }))
        )
    }

    async function cidades(value = '') {
        const response = await api.get(`/api/cities/?search=${value}`)
        const result = await response.data
        return await result.data.map((city) => ({
            label: city.name + ' - ' + city.state.abbreviation,
            value: city.id
        }))
    }

    async function categories(value = '') {
        const response = await api.get(`/api/categories`)
        const result = await response.data
        return setArrayCategories(
            await result.data.map((category) => ({
                label: category.description,
                value: category.id
            }))
        )
    }

    async function buscaEndereco(cep = '') {
        cep = cep.replace('-', '')

        if (cep != '') {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            if (response.status == 200 && !response.data.erro!) {
                setStreet(response.data.logradouro);
                setNumber('');
                setDistrict(response.data.bairro);
                setComplement(response.data.complemento);

                const res = await api.get(`/api/searchCityIbge/?cod=${response.data.ibge}`)
                setCity({value: res.data.data.id, label: res.data.data.name})
            }
        }
    }

    return (
        <>

            {user_incomplete_registration ? (

                <div className="my-4">

                    <div className="alert alert-warning" role="alert">
                        <div className="container">
                            {/* <div className=" alert-icon">
                            </div> */}
                            {/* <i className="now-ui-icons ui-1_bell-53"></i> */}
                            <strong>
                                <i className="fa fa-bell-o fa-fw" aria-hidden="true"></i>&nbsp;
                                Atenção!</strong> Voçê deve completar seu cadastro para prosseguir!
                            {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">

                                    <i className="fa fa-times fa-fw" aria-hidden="true"></i>
                                </span>
                            </button> */}
                            {/* <i className="now-ui-icons ui-1_simple-remove"></i> */}
                        </div>
                    </div>
                </div>
            ) : null}

            <form onSubmit={resgister} autoComplete="off" className="text-left">

                <div className="form-group">
                    <label htmlFor="email">E-mail <i className="text-danger"> *</i></label>
                    <input onChange={evento => setEmail(evento.target.value)} type="email" className="form-control"
                           value={email}
                           id="email" placeholder="Seu email"/>
                </div>
                {!formReduzido && id == undefined ? <>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="password">Senha <i className="text-danger"> *</i></label>
                            <input onChange={evento => setPassword(evento.target.value)} type="password"
                                   className="form-control" id="password" placeholder="******"/>
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="passwordConfirmation">Confirme a senha <i className="text-danger"> *</i></label>
                            <input onChange={evento => setPasswordConfirmation(evento.target.value)} type="password"
                                   className="form-control" id="passwordConfirmation" placeholder="******"/>
                        </div>

                    </div>
                </> : null}

                <div className="form-group">
                    <label htmlFor="fullName">Nome completo <i className="text-danger"> *</i></label>
                    <input onChange={evento => setFullName(evento.target.value)} type="text" className="form-control"
                           value={fullName}
                           id="fullName" placeholder="Seu nome completo"/>
                </div>
                <div className="form-group">
                    <label htmlFor="cpf">CPF <i className="text-danger"> *</i></label>
                    <InputMask mask="999.999.999-99" onChange={evento => setCpf(evento.target.value)} type="text"
                               className="form-control"
                               value={cpf}
                               id="cpf" placeholder="Seu CPF"/>
                </div>

                <div className="form-group">
                    <label htmlFor="phone">Celular <i className="text-danger"> *</i></label>
                    <InputMask mask="(99) 99999-9999" onChange={evento => setPhone(evento.target.value)} type="text"
                               className="form-control"
                               value={phone} id="phone" placeholder="Seu número de celular"/>
                </div>

                <div className="form-row">
                    {/*<div className="form-group col-md-4">*/}
                    {/*    <label htmlFor="category">Categoria</label>*/}

                    {/*    <Select*/}
                    {/*        options={arrayCategories as any}*/}
                    {/*        name='category'*/}
                    {/*        id='category'*/}
                    {/*        onChange={(data: any) => {*/}
                    {/*            setCategory(data.value)*/}
                    {/*        }}*/}
                    {/*        isDisabled={id != undefined && !user_incomplete_registration}*/}
                    {/*        value={arrayCategories.find(option => option.value == category)|| ''}*/}
                    {/*        placeholder='Sua Categoria'*/}
                    {/*        theme={(theme) => (SelectTheme(theme))}*/}
                    {/*    />*/}

                    {/*</div>*/}

                    <div className="form-group col-md-12">
                        <label htmlFor="team">Equipe <i className="text-danger"> *</i></label>

                        <Select
                            options={arrayTeams}
                            name='team'
                            id='team'
                            onChange={(data: any) => {
                                setTeam(data.value)
                            }}
                            value={arrayTeams.find(option => option.value == team) || ''}
                            placeholder='Sua equipe'
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                </div>

                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="genre">Gênero <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Masculino', value: 'M'}}
                            options={[{label: 'Masculino', value: 'M'}, {label: 'Feminino', value: 'F'}]}
                            value={[{label: 'Masculino', value: 'M'}, {
                                label: 'Feminino',
                                value: 'F'
                            }].find(option => option.value == genre)}
                            name='genre'
                            id='genre'
                            onChange={(data: any) => {
                                setGenre(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>
                    <div className="form-group col-md-8">
                        <label htmlFor="birthDate">Data Nascimento <i className="text-danger"> *</i></label>
                        <InputMask mask="99/99/9999" onChange={evento => setBirthDate(evento.target.value)} type="text"
                                   value={birthDate}
                                   className="form-control" id="birthDate" placeholder="Sua data de nascimento"
                        />
                    </div>
                </div>

                {/*{!formReduzido ? <>*/}
                {/*    <div className="form-row">*/}
                {/*        <div className="form-group col-md-4">*/}
                {/*            <label htmlFor="zipCode">Cep</label>*/}
                {/*            <InputMask mask="99999-999"*/}
                {/*                       onChange={evento => setZipCode(evento.target.value)} type="text"*/}
                {/*                       onBlur={evento => buscaEndereco(evento.target.value)}*/}
                {/*                       className="form-control"*/}
                {/*                       value={zipCode}*/}
                {/*                       id="zipCode" placeholder="Seu cep"/>*/}

                {/*        </div>*/}
                {/*        <div className="form-group col-md-8">*/}
                {/*            <label htmlFor="street">Rua</label>*/}
                {/*            <input onChange={evento => setStreet(evento.target.value)} type="text"*/}
                {/*                   className="form-control"*/}
                {/*                   value={street}*/}
                {/*                   id="street" placeholder="Rua"/>*/}
                {/*        </div>*/}
                {/*        <div className="form-group col-md-4">*/}
                {/*            <label htmlFor="number">Numero</label>*/}
                {/*            <input onChange={evento => setNumber(evento.target.value)} type="text"*/}
                {/*                   className="form-control"*/}
                {/*                   value={number}*/}
                {/*                   id="number" placeholder="Numero"/>*/}
                {/*        </div>*/}

                {/*        <div className="form-group col-md-8">*/}
                {/*            <label htmlFor="district">Bairro</label>*/}
                {/*            <input onChange={evento => setDistrict(evento.target.value)} type="text"*/}
                {/*                   value={district}*/}
                {/*                   className="form-control" id="district" placeholder="Seu bairro"/>*/}
                {/*        </div>*/}
                {/*        <div className="form-group col-md-6">*/}
                {/*            <label htmlFor="complement">Complemento</label>*/}
                {/*            <input onChange={evento => setComplement(evento.target.value)} type="text"*/}
                {/*                   value={complement}*/}
                {/*                   className="form-control" id="complement" placeholder="Ex: Ap 123"/>*/}
                {/*        </div>*/}

                {/*        <div className="form-group col-md-6">*/}
                {/*            <label htmlFor="city">Cidade</label>*/}

                {/*            <Async*/}
                {/*                name="search"*/}
                {/*                isLoading={false}*/}
                {/*                cacheOptions*/}
                {/*                loadOptions={cidades}*/}
                {/*                noOptionsMessage={() => "Nenhum registro encontrado"}*/}
                {/*                placeholder='Sua cidade'*/}
                {/*                onChange={(data: any) => {*/}
                {/*                    setCity(data);*/}
                {/*                }}*/}
                {/*                value={city}*/}
                {/*                theme={(theme) => (SelectTheme(theme))}*/}
                {/*                defaultOptions*/}
                {/*            />*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</> : null}*/}


                <button type="submit" className="btn btn-primary btn-block mb-4">
                    {id == undefined || formReduzido ? 'Registrar' : 'Salvar'}
                </button>


                {/* <div className="text-center">
                    <p>or sign up with:</p>
                    <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-facebook-f"></i>
                    </button>

                    <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-google"></i>
                    </button>

                </div> */}
            </form>

        </>
    );

};