import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import api from '../../services/api'
import Swal from 'sweetalert2'

export default function ChangePasswordForm() {
    const navigate = useNavigate()
    const {token} = useParams()


    // const [passwordUpdated, setPasswordUpdated] = useState('')

    const [currentpassword, setCurrentpassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')

    useEffect(() => {
    }, [])

    const forgot = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault()

        if (password == passwordConfirmation) {
            api.post('/api/changePassword', {
                password: password,
                token: token,
            }).then((result) => {
                // console.log(result)
                Swal.fire({
                    title: 'Sucesso!',
                    text: result.data.message,
                    icon: 'success',
                    timer: 5000,
                }).then((result) => {
                    // return navigate("/login");
                })
            }).catch(erro => {
                    console.log(erro)
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.sqlMessage,
                        icon: 'error',
                    })
                },
            )
        } else {
            Swal.fire({
                title: 'Erro!',
                text: 'Senhas devem ser iguais!',
                icon: 'error',
            })
        }


    }


    return (
        <>

            <section className='text-center text-lg-start'>

                <div className='py-5 text-center'>
                    <div className='container'>
                        <div className='row'>
                            <div className='mx-auto col-md-10 col-10 bg-white p-5'>
                                {/*<h1 className='mb-4'>Recuperar senha</h1>*/}

                                <form onSubmit={forgot} autoComplete='off' className='text-left'>
                                    <h5>Preencha os campos abaixo para finalizar sua troca de senha.</h5>

                                    <div className='form-row'>
                                        <div className='form-group col-md-12'>
                                            <label htmlFor='email'>Senha atual</label>
                                            <input onChange={evento => setCurrentpassword(evento.target.value)}
                                                   type='password' className='form-control'
                                                   value={currentpassword}
                                                   id='Currentpassword' placeholder='Sua senha atual'/>
                                        </div>

                                    </div>

                                    <div className='form-row'>
                                        <div className='form-group col-md-6'>
                                            <label htmlFor='email'>Infome a nova senha</label>
                                            <input onChange={evento => setPassword(evento.target.value)} type='password'
                                                   className='form-control'
                                                   value={password}
                                                   id='password' placeholder='Sua senha nova'/>
                                        </div>

                                        <div className='form-group col-md-6'>
                                            <label htmlFor='passwordConfirmation'>Confirme a nova senha</label>
                                            <input onChange={evento => setPasswordConfirmation(evento.target.value)}
                                                   type='password'
                                                   className='form-control' id='passwordConfirmation'
                                                   placeholder='Confirme a nova senha'/>
                                        </div>
                                    </div>

                                    <button type='submit' className='btn btn-primary btn-block mb-4'>
                                        Alterar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

};