import { useEffect, useState } from 'react'
import api from '../../services/api'
import { useNavigate } from 'react-router'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import Async from 'react-select/async'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { validaCPF } from '../../assets/js/helper'
import {SelectTheme} from "../../components/selectTheme";

export default function FormAthletes({ath = ''}) {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('token') || "").user

  let { id} = useParams()

  const [userId, setUser] = useState(0)
  const [addressId, setAddress] = useState(0)

  const [email, setEmail] = useState('')
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [active, setActive] = useState('')
  const [fullName, setFullName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [cpf, setCpf] = useState('')
  const [cpfFormated, setCpfF] = useState('')
  const [phone, setPhone] = useState('')
  const [genre, setGenre] = useState('M' as any)
  const [birthDate, setBirthDate] = useState('')
  const [team, setTeam] = useState('')
  const [category, setCategory] = useState('' as any)

  const [zipCode, setZipCode] = useState('')
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [district, setDistrict] = useState('')
  const [complement, setComplement] = useState('')
  const [city, setCity] = useState({} as any)

  const [arrayTeams, setArrayTeams] = useState([] as any)
  const [arrayCategories, setArrayCategories] = useState([] as any)


  const objReg:any = {
    "id": id,
    "active": active,
    "full_name": fullName,
    "display_name": displayName,
    "birth_date": (birthDate.substr(6, 4) + '-' + birthDate.substr(3, 2) + '-' + birthDate.substr(0, 2)),
    "cpf": cpf,
    "genre": genre,
    "phone": phone,
    "address_id": addressId,
    "category_id": category,
    "team_id": team,
    "user": {
      "id": userId,
      "email": email,
      "username": fullName.split(' ')[0],
      "cpf": cpf
    },
    "address": {
      "id": addressId,
      "street": street,
      "number": number,
      "district": district,
      "zip_code": zipCode,
      "complement": complement,
      "city_id": city.value,
    }
  }

  // const getData = async () => {
  //   await api.get('/api/athletes').then((response) => {
  //     const data = response.data.data
  //     console.log(response.data.data)
  //     // setAthletes(data)
  //   })
  // }

  useEffect(() => {
    if(user?.organizer === "0" && user?.role != "admin"){
      localStorage.setItem('hasError', JSON.stringify({title:"Atenção!" ,message:"Você não tem permissão. ",type: 'danger'}));
      return navigate("/home");
    }

    teams()
    categories()

    if (ath){
      id = ath
    }

    if (id != undefined) {
      api.get('/api/athletes/' + id)
        .then(resposta => {
          const athlete = resposta.data.data

          setUser(athlete.user?.id)
          setAddress(athlete.address?.id)

          setEmail(athlete.user?.email)

          setActive(athlete?.active)
          setFullName(athlete?.full_name)
          setDisplayName(athlete?.display_name)
          setCpf(athlete?.cpf)
          setCpfF(athlete?.cpf_formated)
          setPhone(athlete?.phone)
          setBirthDate(new Date(athlete?.birth_date).toLocaleString('pt-BR'))
          setGenre(athlete?.genre)
          setCategory(athlete?.category_id)
          setTeam(athlete?.team_id)

          setZipCode(athlete?.address.zip_code)
          setStreet(athlete?.address.street)
          setNumber(athlete?.address.number)
          setDistrict(athlete?.address.district)
          setComplement(athlete?.address.complement)
          setCity({
            value: athlete?.address.city_id,
            label: athlete?.address.city.name + ' - ' + athlete?.address.city.state.abbreviation,
          })
        })

    }
  }, [])

  const validadeRegister = () => {
    if(changePassword){
      if (!password) {
        throw "Senha deve ser informado";
      }

      if (!passwordConfirmation) {
        throw "Confimação da senha deve ser informado";
      }
    }

    if (!email) {
      throw "E-mail deve ser informado";
    }

    if (!fullName) {
      throw "Nome completo deve ser informado";
    }

    if (!cpf) {
      throw "CPF deve ser informado";
    }

    if(!validaCPF(cpf)){
      throw "CPF inválido";
    }

    if (!phone) {
      throw "Celular deve ser informado";
    }

    if (!category) {
      throw "Ccategoria deve ser selecionada";
    }

    if (!team) {
      throw "Equipe deve ser selecionada";
    }

    if (!birthDate) {
      throw "Data de nascimento deve ser informada";
    }
  };

  const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
    try {

      evento.preventDefault();

      if (user?.organizer === "0" && user?.role != "admin"){
        localStorage.setItem('hasError', JSON.stringify({title:"Atenção!" ,message:"Você não tem permissão. ",type: 'danger'}));
        return navigate("/home");
      }

      validadeRegister()

      if (id == undefined) {
          // api.post('/api/athletes', objReg).then((result) => {
          //   console.log(result)
          //   Swal.fire({
          //     title: 'Sucesso!',
          //     text: result.data.message,
          //     icon: 'success',
          //     timer: 5000
          //   }).then((result) => {
          //     // if(!formReduzido){
          //     //   return navigate("/login");
          //     // }else{
          //     //   resetaForm();
          //     // }
          //   });
          // })
          //   .catch(erro => {
          //       console.log(erro.response)
          //       Swal.fire({
          //         title: 'Erro!',
          //         text: erro.response.data.sqlMessage,
          //         icon: 'error',
          //       })
          //     }
          //   );


      } else {

        if (changePassword && password.length > 0 && user?.role === "admin"){
          objReg.user.password = password
        }

        api.put('/api/athletes/' + id, objReg).then((result) => {
            Swal.fire({
                title: 'Sucesso!',
                text: result.data.message,
                icon: 'success',
            }).then((result) => {;
                return navigate("/adm/athletes");
            });
        })
            .catch(erro => Swal.fire({
                title: 'Erro!',
                text: erro.response.data.sqlMessage,
                icon: 'error',
            }))
      }
    }catch (err: any) {
      Swal.fire({
        title: 'Erro!',
        text: err,
        icon: 'error',
      })
    }

  }

  async function teams(value = '') {
    const response = await api.get(`/api/allTeams`)
    const result = await response.data
    return setArrayTeams(
      await result.data.map((club) => ({
        label: club.fantasia,
        value: club.id,
      })),
    )
  }

  async function cidades(value = '') {
    const response = await api.get(`/api/cities/?search=${value}`)
    const result = await response.data
    return await result.data.map((city) => ({
      label: city.name + ' - ' + city.state.abbreviation,
      value: city.id,
    }))
  }

  async function categories(value = '') {
    const response = await api.get(`/api/categories`)
    const result = await response.data
    return setArrayCategories(
      await result.data.map((category) => ({
        label: category.description,
        value: category.id,
      })),
    )
  }

  async function buscaEndereco(cep = '') {
    cep = cep.replace('-', '')

    if (cep != '') {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (response.status == 200 && !response.data.erro!) {
        setStreet(response.data.logradouro)
        setNumber('')
        setDistrict(response.data.bairro)
        setComplement(response.data.complemento)

        const res = await api.get(`/api/searchCityIbge/?cod=${response.data.ibge}`)
        setCity({ value: res.data.data.id, label: res.data.data.name })
      }
    }
  }

  return (
    <>
      <form onSubmit={resgister} autoComplete='off' className='text-left'>
        <div className='form-row'>
          {user?.role === "admin" ? <>
            <div className='form-group col-md-4'>
              <label htmlFor='active'>Ativo <i className="text-danger"> *</i></label>
              <Select
                defaultValue={{ label: 'Ativo', value: '1' }}
                options={[{ label: 'Ativo', value: '1' }, { label: 'Inativo', value: '0' }]}
                value={[{ label: 'Ativo', value: '1' }, {
                  label: 'Inativo',
                  value: '0',
                }].find(option => option.value == active)}
                name='active'
                id='active'
                noOptionsMessage={() => 'Nenhum registro encontrado'}
                onChange={(data: any) => {
                  setActive(data.value)
                }}
                theme={(theme) => (SelectTheme(theme))}
              />
            </div>

            <div className='form-group col-md-2'>
              <label htmlFor='editPassword'>&nbsp;</label>
              <button type="button" onClick={() => setChangePassword(!changePassword)} className="btn btn-warning form-control"> Editar senha</button>
            </div>
          </> : null}
        </div>

        <div className='form-group'>
          <label htmlFor='email'>E-mail <i className="text-danger"> *</i></label>
          <input onChange={evento => setEmail(evento.target.value)} type='email' className='form-control'
                 value={email}
                 id='email' placeholder='Seu email' />
        </div>

        {changePassword && user?.role === "admin" ? <>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="password">Senha</label>
              <input onChange={evento => setPassword(evento.target.value)} type="password"
                     className="form-control" id="password" placeholder="******"/>
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="passwordConfirmation">Confirme a senha</label>
              <input onChange={evento => setPasswordConfirmation(evento.target.value)} type="password"
                     className="form-control" id="passwordConfirmation" placeholder="******"/>
            </div>
          </div>
          <hr/>
        </> : null}

        <div className='form-row'>
          <div className='form-group col-md-12'>
            <label htmlFor='fullName'>Nome completo <i className="text-danger"> *</i></label>
            <input onChange={evento => setFullName(evento.target.value)} type='text' className='form-control'
                   value={fullName}
                   id='fullName' placeholder='Seu nome completo' />
          </div>

          {/*<div className='form-group col-md-6'>*/}
          {/*  <label htmlFor='fullName'>Nome de exibição <i className="text-danger"> *</i></label>*/}
          {/*  <input onChange={evento => setDisplayName(evento.target.value)} type='text' className='form-control'*/}
          {/*         value={displayName}*/}
          {/*         id='displayName' placeholder='Nome de exibição' />*/}
          {/*</div>*/}
        </div>

        <div className='form-row'>
          <div className='form-group col-md-6'>
            <label htmlFor='cpf'>CPF <i className="text-danger"> *</i></label>
            {user?.role === "admin" ?
              <InputMask mask='999.999.999-99' onChange={evento => setCpf(evento.target.value)} type='text'
                       className='form-control'
                       value={cpf}
                       id='cpf' placeholder='Seu CPF' />
              :
                <input type='text' readOnly={true} className='form-control' value={cpfFormated} id='cpf' placeholder='***.***.***-**' />
             }
          </div>

          <div className='form-group col-md-6'>
            <label htmlFor='phone'>Celular <i className="text-danger"> *</i></label>
            <InputMask mask='(99) 99999-9999' onChange={evento => setPhone(evento.target.value)} type='text'
                       className='form-control'
                       value={phone} id='phone' placeholder='Seu número de celular' />
          </div>
        </div>

        <div className='form-row'>
          <div className='form-group col-md-4'>
            <label htmlFor='genre'>Gênero <i className="text-danger"> *</i></label>
            <Select
              defaultValue={{ label: 'Masculino', value: 'M' }}
              options={[{ label: 'Masculino', value: 'M' }, { label: 'Feminino', value: 'F' }]}
              value={[{ label: 'Masculino', value: 'M' }, {
                label: 'Feminino',
                value: 'F',
              }].find(option => option.value == genre)}
              name='genre'
              id='genre'
              noOptionsMessage={() => 'Nenhum registro encontrado'}
              onChange={(data: any) => {
                setGenre(data.value)
              }}
              theme={(theme) => (SelectTheme(theme))}
            />
          </div>
          <div className='form-group col-md-8'>
            <label htmlFor='birthDate'>Data Nascimento <i className="text-danger"> *</i></label>
            <InputMask mask='99/99/9999' readOnly={user?.role !== "admin"} onChange={evento => setBirthDate(evento.target.value)} type='text'
                       value={birthDate}
                       className='form-control' id='birthDate' placeholder='Sua data de nascimento'
            />
          </div>
        </div>

        <div className='form-row'>
          <div className='form-group col-md-4'>
            <label htmlFor='category'>Categoria <i className="text-danger"> *</i></label>

            <Select
              options={arrayCategories as any}
              name='category'
              id='category'
              onChange={(data: any) => {
                setCategory(data.value)
              }}
              noOptionsMessage={() => 'Nenhum registro encontrado'}
              isDisabled={user?.role != "admin"}
              value={arrayCategories.find(option => option.value == category) || ''}
              placeholder='Sua Categoria'
               theme={(theme) => (SelectTheme(theme))}
            />

          </div>

          <div className='form-group col-md-8'>
            <label htmlFor='team'>Equipe <i className="text-danger"> *</i></label>

            <Select
              options={arrayTeams}
              name='team'
              id='team'
              isDisabled={user?.role != "admin"}
              onChange={(data: any) => {
                setTeam(data.value)
              }}
              noOptionsMessage={() => 'Nenhum registro encontrado'}
              value={arrayTeams.find(option => option.value == team) || ''}
              placeholder='Sua equipe'
               theme={(theme) => (SelectTheme(theme))}
            />

          </div>

        </div>


        <h3 className="mt-3">Endereço</h3>

        <div className='form-row'>
          <div className='form-group col-md-4'>
            <label htmlFor='zipCode'>Cep <i className="text-danger"> *</i></label>
            <InputMask mask='99999-999'
                       onChange={evento => setZipCode(evento.target.value)} type='text'
                       onBlur={evento => buscaEndereco(evento.target.value)}
                       className='form-control'
                       value={zipCode}
                       id='zipCode' placeholder='Seu cep' />

          </div>
          <div className='form-group col-md-8'>
            <label htmlFor='street'>Rua <i className="text-danger"> *</i></label>
            <input onChange={evento => setStreet(evento.target.value)} type='text'
                   className='form-control'
                   value={street}
                   id='street' placeholder='Rua' />
          </div>
          <div className='form-group col-md-4'>
            <label htmlFor='number'>Numero <i className="text-danger"> *</i></label>
            <input onChange={evento => setNumber(evento.target.value)} type='text'
                   className='form-control'
                   value={number}
                   id='number' placeholder='Numero' />
          </div>

          <div className='form-group col-md-8'>
            <label htmlFor='district'>Bairro <i className="text-danger"> *</i></label>
            <input onChange={evento => setDistrict(evento.target.value)} type='text'
                   value={district}
                   className='form-control' id='district' placeholder='Seu bairro' />
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='complement'>Complemento <i className="text-danger"> *</i></label>
            <input onChange={evento => setComplement(evento.target.value)} type='text'
                   value={complement}
                   className='form-control' id='complement' placeholder='Ex: Ap 123' />
          </div>

          <div className='form-group col-md-6'>
            <label htmlFor='city'>Cidade <i className="text-danger"> *</i></label>

            <Async
              name='search'
              isLoading={false}
              cacheOptions
              loadOptions={cidades}
              noOptionsMessage={() => 'Nenhum registro encontrado'}
              placeholder='Sua cidade'
              onChange={(data: any) => {
                setCity(data)
              }}
              value={city}
              theme={(theme) => (SelectTheme(theme))}
              defaultOptions
            />

          </div>
        </div>


        <div className='row py-4'>
          <div className='col-md-12  text-right'>
            <button type='button' className='btn btn-danger mr-1' onClick={() => navigate(-1)}>Cancelar<br /></button>
            <button type='submit' className='btn btn-success'> Salvar</button>
          </div>
        </div>

      </form>


    </>


  )
}