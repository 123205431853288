import { useState } from 'react';
import Async from 'react-select/async';
import Swal from 'sweetalert2';
import RegisterForm from '../pages/register/registerForm';
import api from "../services/api";
import { SelectTheme } from './selectTheme'

export default function SearchPeople({childToParent , station}) {
    const INITIAL_DATA = {
        value: 0,
        label: 'Selecione o atleta',
    };

    const [selectData, setselectData] = useState(INITIAL_DATA);

    const mapResponseToValuesAndLabels = (data: any) => ({
        value: data.user_id,
        label: data.full_name,
    });

    async function atlhetes(value = '') {
        // await fetch(`http:///api/athletes`)
        // .then((response) => response.json())

        const response = await api.get(`/api/athletes/getToSelect/?search=${value}`)
        const result = await response.data
        return await result.data.map((athlete) => ({
            label: athlete.full_name,
            value: athlete.id
        }))

        // const data =  await api.get(`/api/athletes`)
        //     .then((response) => response.data.map(mapResponseToValuesAndLabels))
        //     .then((final) =>
        //         final.filter((i: any) => i.label.toLowerCase().includes(value.toLowerCase()))
        //     );
        //
        // return data;
    }

    const selecionarPessoa = () => {
        if(selectData.value == 0){
           return Swal.fire({
                title: 'Erro!',
                text: 'Selecione um atleta',
                icon: 'error',
            });
        }
        childToParent( {atlhelete: selectData.value,  station:station})
    }

    return (
        <>

            <button className="btn btn-sm btn-info" data-toggle="modal" data-target={`#modalSearchPeople${station}`}>
                Adicionar atleta
            </button>

            <div className="modal fade" id={`modalSearchPeople${station}`} role="dialog" aria-labelledby="modalSearchPeopleLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </button>
                            <h4 className="title title-up">Atletas</h4>
                        </div>
                        <div className="modal-body">
                            <div className="my-2">
                                <button className="btn btn-primary" type="button" data-toggle="collapse" data-target={`#collapseAthletes${station}`} aria-expanded="false" aria-controls={`collapseAthletes${station}`}>
                                    Cadastrar
                                </button>
                            </div>

                            <Async
                                name="search"
                                isLoading={false}
                                cacheOptions
                                loadOptions={atlhetes}
                                noOptionsMessage={() => "Nenhum registro encontrado"}
                                onChange={(data: any) => {
                                    setselectData(data);
                                }}
                                theme={(theme) => (SelectTheme(theme))}
                                value={selectData}
                                defaultOptions/>

                            <div className="collapse my-2" id={`collapseAthletes${station}`}>
                                <div className="card card-body">
                                    <RegisterForm formReduzido={true} station={station} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={selecionarPessoa} className="btn btn-success">Adicionar</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}