import axios from 'axios';
import Swal from "sweetalert2";

let api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST
});

api.interceptors.response.use((response) => response, (error) => {
    // whatever you want to do with the error
    if(error?.response?.status === 401){
        Swal.fire({
            title: 'Atenção!',
            text: 'Sua sessão expirou. Faça login novamente para continuar.',
            icon: 'error',
            timer: 10000
        }).then((result) => {
            localStorage.removeItem('token');
            return window.location.reload();
        });
    }
    throw error;
});
export default api