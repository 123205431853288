import {useEffect, useState} from 'react'
import api from '../../services/api'
import {useNavigate} from 'react-router'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import {useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import { SelectTheme } from '../../components/selectTheme'

export default function FormAmmo() {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('token') || "").user

    let {id} = useParams()
    const [amount, setAmount] = useState("1")
    const [releaseDate, setReleaseDate] = useState(new Date().toLocaleString('pt-BR'))
    const [operation, setOperation] = useState('' as any)
    const [observation, setObservation] = useState('')

    const [caliber, setCaliber] = useState('' as any)
    const [arrayCalibers, setArrayCalibers] = useState([] as any)

    const objReg: any = {
        "id": id,
        "observation": observation,
        "operation": operation,
        "release_date": (releaseDate.substr(6, 4) + '-' + releaseDate.substr(3, 2) + '-' + releaseDate.substr(0, 2)),
        "caliber_id": caliber,
    }

    useEffect(() => {
        caliberss()

        if (id != undefined) {
            api.get('/api/weapons/' + id)
                .then(resposta => {
                    const ammo = resposta.data

                    setAmount(ammo?.amount)
                    setReleaseDate(new Date(ammo?.release_date).toLocaleString('pt-BR'))
                    setOperation(ammo?.operation)
                    setCaliber(ammo?.caliber_id)
                    setObservation(ammo?.observation)
                })

        }
    }, [])

    async function caliberss(value = '') {
        const response = await api.post(`/api/getMyCalibers`)
        const result = await response.data
        return setArrayCalibers(
            await result.map((caliber) => ({
                label: caliber.description,
                value: caliber.id,
            })),
        )
    }

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            if (id == undefined) {
                // api.post('/api/athletes', objReg).then((result) => {
                //   console.log(result)
                //   Swal.fire({
                //     title: 'Sucesso!',
                //     text: result.data.message,
                //     icon: 'success',
                //     timer: 5000
                //   }).then((result) => {
                //     // if(!formReduzido){
                //     //   return navigate("/login");
                //     // }else{
                //     //   resetaForm();
                //     // }
                //   });
                // })
                //   .catch(erro => {
                //       console.log(erro.response)
                //       Swal.fire({
                //         title: 'Erro!',
                //         text: erro.response.data.sqlMessage,
                //         icon: 'error',
                //       })
                //     }
                //   );


            } else {
                api.put('/api/weapons/' + id, objReg).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                    }).then((result) => {
                        // return navigate("/adm/athletes");
                    });
                })
                    .catch(erro => Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.sqlMessage,
                        icon: 'error',
                    }))
            }
        } catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className='text-left'>
                <div className='form-row'>
                    <div className='form-group col-md-4'>
                        <label htmlFor='caliber'>Calibre <i className="text-danger"> *</i></label>
                        <Select
                            options={arrayCalibers as any}
                            name='caliber'
                            id='caliber'
                            onChange={(data: any) => {
                                setCaliber(data.value)
                            }}
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            value={arrayCalibers.find(option => option.value == caliber) || ''}
                            placeholder='Calibre da arma'
                            isDisabled={!!id}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>


                    <div className='form-group col-md-4'>
                        <label htmlFor='amount'>Quantidade <i className="text-danger"> *</i></label>
                        <input onChange={evento => setAmount(evento.target.value)} type='number'
                               className='form-control'
                               value={amount}
                               id='amount' placeholder='Quantidade'/>
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='weapon_category_id'>Operação <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Saida', value: 'output'}}
                            options={[{label: 'Saida', value: 'output'}, {label: 'Entrada', value: 'input'}]}
                            value={[{label: 'Saida', value: 'output'}, {
                                label: 'Entrada',
                                value: 'input',
                            }].find(option => option.value == operation)}
                            name='operation'
                            id='operation'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setOperation(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='release_date'>Data <i className="text-danger"> *</i></label>
                        <InputMask mask='99/99/9999' onChange={evento => setReleaseDate(evento.target.value)}
                                   type='text'
                                   value={releaseDate}
                                   className='form-control' id='release_date' placeholder='Data do lançamento'
                        />
                    </div>
                </div>

                <div className='form-row'>
                    <div className='form-group col-md-12'>
                        <label htmlFor='observation'>Observação <i className="text-danger"> *</i></label>
                        <textarea id="observation" name="observation" className='form-control'
                                  value={observation} onChange={evento => setObservation(evento.target.value)}
                                  rows={4} cols={50}/>
                    </div>
                </div>

                <div className='row py-4'>
                    <div className='col-md-12  text-right'>
                        <button type='button' className='btn btn-danger mr-1' onClick={() => navigate(-1)}>Cancelar<br/>
                        </button>
                        <button type='submit' className='btn btn-success'> Salvar</button>
                    </div>
                </div>
            </form>

            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                Launch demo modal
            </button>

            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            ...
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}