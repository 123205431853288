import { useEffect, useState } from 'react'
import api from '../../services/api'
import { useNavigate } from 'react-router'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {SelectTheme} from "../../components/selectTheme";


export default function FormWeapons() {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('token') || "").user

  let { id} = useParams()
  const [main, setMain] = useState('')
  const [description, setDescription] = useState('')
  const [crafValidity, setCrafValidity] = useState('')
  const [trafficGuideValidity, setTrafficGuideValidity] = useState('')
  const [huntingGuideValidity, setHunting_guide_validity] = useState('')
  const [caliber, setCaliber] = useState('' as any)
  const [weaponCategory, setWeaponCategory] = useState('' as any)

  const [arrayCalibers, setArrayCalibers] = useState([] as any)
  const [arrayCategories, setArrayWeaponCategories] = useState([] as any)


  const objReg:any = {
    "id": id,
    "main": main,
    "description": description,
    "craf_validity": (crafValidity.substr(6, 4) + '-' + crafValidity.substr(3, 2) + '-' + crafValidity.substr(0, 2)),
    "traffic_guide_validity": (crafValidity.substr(6, 4) + '-' + crafValidity.substr(3, 2) + '-' + crafValidity.substr(0, 2)),
    "hunting_guide_validity": (crafValidity.substr(6, 4) + '-' + crafValidity.substr(3, 2) + '-' + crafValidity.substr(0, 2)),
    "weapon_category_id": weaponCategory,
    "caliber_id": caliber,
  }

  useEffect(() => {
    calibers()
    weaponCategories()

    if (id != undefined) {
      api.get('/api/weapons/' + id)
        .then(resposta => {
          const weapon = resposta.data

          setMain(weapon?.main)
          setDescription(weapon?.description)
          setCrafValidity(new Date(weapon?.craf_validity).toLocaleString('pt-BR'))
          setTrafficGuideValidity(new Date(weapon?.traffic_guide_validity).toLocaleString('pt-BR'))
          setHunting_guide_validity(weapon?.hunting_guide_validity ? new Date(weapon?.hunting_guide_validity).toLocaleString('pt-BR') : '')
          setWeaponCategory(weapon?.weapon_category_id)
          setCaliber(weapon?.caliber_id)


        })

    }
  }, [])

  const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
    try {

      evento.preventDefault();

      if (id == undefined) {
          // api.post('/api/athletes', objReg).then((result) => {
          //   console.log(result)
          //   Swal.fire({
          //     title: 'Sucesso!',
          //     text: result.data.message,
          //     icon: 'success',
          //     timer: 5000
          //   }).then((result) => {
          //     // if(!formReduzido){
          //     //   return navigate("/login");
          //     // }else{
          //     //   resetaForm();
          //     // }
          //   });
          // })
          //   .catch(erro => {
          //       console.log(erro.response)
          //       Swal.fire({
          //         title: 'Erro!',
          //         text: erro.response.data.sqlMessage,
          //         icon: 'error',
          //       })
          //     }
          //   );


      } else {
        api.put('/api/weapons/' + id, objReg).then((result) => {
            Swal.fire({
                title: 'Sucesso!',
                text: result.data.message,
                icon: 'success',
            }).then((result) => {;
                // return navigate("/adm/athletes");
            });
        })
            .catch(erro => Swal.fire({
                title: 'Erro!',
                text: erro.response.data.sqlMessage,
                icon: 'error',
            }))
      }
    }catch (err: any) {
      Swal.fire({
        title: 'Erro!',
        text: err,
        icon: 'error',
      })
    }


  }

  async function weaponCategories(value = '') {
    const response = await api.get(`/api/weaponCategories`)
    const result = await response.data
    return setArrayWeaponCategories(
      await result.data.map((category) => ({
        label: category.description,
        value: category.id,
      })),
    )
  }

  async function calibers(value = '') {
    const response = await api.get(`/api/calibers`)
    const result = await response.data
    return setArrayCalibers(
        await result.data.map((club) => ({
          label: club.description,
          value: club.id,
        })),
    )
  }

  return (
    <>
      <form onSubmit={resgister} autoComplete='off' className='text-left'>

        <div className='form-row'>

          <div className='form-group col-md-12'>
          {/*<div className="form-check">*/}
            <label className="form-check-label">
              <input className="form-check-input" id="main" type="checkbox"/>
                Principal
            </label>
          </div>

          <div className='form-group col-md-12'>
          <label htmlFor='description'>Descricao <i className="text-danger"> *</i></label>
          <input onChange={evento => setDescription(evento.target.value)} type='text' className='form-control'
                 value={description}
                 id='description' placeholder='Seu description' />
          </div>
        </div>

        <div className='form-row'>
          <div className='form-group col-md-6'>
            <label htmlFor='weapon_category_id'>Tipo <i className="text-danger"> *</i></label>

            <Select
                options={arrayCategories as any}
                name='weapon_category_id'
                id='weapon_category_id'
                onChange={(data: any) => {
                  setWeaponCategory(data.value)
                }}
                noOptionsMessage={() => 'Nenhum registro encontrado'}
                // isDisabled={user?.role != "admin"}
                value={arrayCategories.find(option => option.value == weaponCategory) || ''}
                placeholder='Tipo da Arma'
                theme={(theme) => (SelectTheme(theme))}
            />

          </div>

          <div className='form-group col-md-6'>
            <label htmlFor='caliber_id'>Calibre <i className="text-danger"> *</i></label>

            <Select
                options={arrayCalibers}
                name='caliber_id'
                id='caliber_id'
                isDisabled={weaponCategory == ""}
                onChange={(data: any) => {
                  setCaliber(data.value)
                }}
                noOptionsMessage={() => 'Nenhum registro encontrado'}
                value={arrayCalibers.find(option => option.value == caliber) || ''}
                placeholder='Calibre da Arma'
                theme={(theme) => (SelectTheme(theme))}
            />

          </div>

        </div>

        <div className='form-row'>
          <div className='form-group col-md-4'>
            <label htmlFor='craf_validity'>Vencimeto do CRAF <i className="text-danger"> *</i></label>
            <InputMask mask='99/99/9999' onChange={evento => setCrafValidity(evento.target.value)} type='text'
                       value={crafValidity}
                       className='form-control' id='craf_validity' placeholder='Validade do CRAF'
            />
          </div>

          <div className='form-group col-md-4'>
            <label htmlFor='traffic_guide_validity'>Vencimeto da Guia de transito <i className="text-danger"> *</i></label>
            <InputMask mask='99/99/9999' onChange={evento => setTrafficGuideValidity(evento.target.value)} type='text'
                       value={trafficGuideValidity}
                       className='form-control' id='traffic_guide_validity' placeholder='Validade da guia de transito'
            />
          </div>

          <div className='form-group col-md-4'>
            <label htmlFor='hunting_guide_validity'>Vencimeto da Guia de caça <i className="text-danger"> *</i></label>
            <InputMask mask='99/99/9999' onChange={evento => setHunting_guide_validity(evento.target.value)} type='text'
                       value={huntingGuideValidity}
                       className='form-control' id='hunting_guide_validity' placeholder='Validade da guia caça'
            />
          </div>
        </div>

        {/*<table className="table table-striped">*/}
        {/*  <thead>*/}
        {/*  <tr >*/}
        {/*    <th scope="col">Quantidade</th>*/}
        {/*    <th scope="col">Operação</th>*/}
        {/*    <th scope="col">Data</th>*/}
        {/*  </tr>*/}
        {/*  </thead>*/}
        {/*  <tbody>*/}
        {/*  <tr className="table-success">*/}
        {/*    <td >400</td>*/}
        {/*    <td> + Entrada</td>*/}
        {/*    <td>01/01/2023</td>*/}
        {/*  </tr>*/}
        {/*  <tr className="table-danger">*/}
        {/*    <td >25</td>*/}
        {/*    <td> - Saida</td>*/}
        {/*    <td>01/03/2023</td>*/}
        {/*  </tr>*/}
        {/*  <tr className="table-info">*/}
        {/*    <td scope="row">350 Saldo Atual</td>*/}
        {/*    <td></td>*/}
        {/*    <td></td>*/}
        {/*  </tr>*/}
        {/*  </tbody>*/}
        {/*</table>*/}

        <div className='row py-4'>
          <div className='col-md-12  text-right'>
            <button type='button' className='btn btn-danger mr-1' onClick={() => navigate(`/weapons`)}>Cancelar<br /></button>
            <button type='submit' className='btn btn-success'> Salvar</button>
          </div>
        </div>
      </form>
    </>

  )
}