import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../services/api'
import Swal from 'sweetalert2'

export default function ResetPasswordForm() {
  const navigate = useNavigate()
  const { token } = useParams()


  const [passwordUpdated, setPasswordUpdated] = useState('')

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  useEffect(() => {
  }, [])

  const forgot = (evento: React.FormEvent<HTMLFormElement>) => {
    evento.preventDefault()

    if (password == passwordConfirmation) {
      api.post('/api/reset-password', {
        password: password,
        token: token,
      }).then((result) => {
        // console.log(result)
        Swal.fire({
          title: 'Sucesso!',
          text: result.data.message,
          icon: 'success',
          timer: 5000,
        }).then((result) => {
            return navigate("/login");
        })
      }).catch(erro => {
          console.log(erro)
          Swal.fire({
            title: 'Erro!',
            text: erro.response.data.sqlMessage,
            icon: 'error',
          })
        },
      )
    } else {
      Swal.fire({
        title: 'Erro!',
        text: 'Senhas devem ser iguais!',
        icon: 'error',
      })
    }


  }


  return (
    <>

      {!passwordUpdated ? (

          <form onSubmit={forgot} autoComplete='off' className='text-left'>

            <h5>Preencha os campos abaixo com a nova senha, para finalizar sua troca de senha.</h5>

            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='email'>Infome nova senha</label>
                <input onChange={evento => setPassword(evento.target.value)} type='password' className='form-control'
                       value={password}
                       id='password' placeholder='Seu password' />
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='passwordConfirmation'>Confirme a senha</label>
                <input onChange={evento => setPasswordConfirmation(evento.target.value)} type='password'
                       className='form-control' id='passwordConfirmation' placeholder='******' />
              </div>
            </div>

            <button type='submit' className='btn btn-primary btn-block mb-4'>
              Continuar
            </button>
          </form>
        ) :
        (
          <>
            <h4>E-mail confirmado com sucesso, para finalizar a troca da sua senha clique no link enviado para o seu
              e-mail.</h4>
          </>
        )


      }
    </>
  )

};