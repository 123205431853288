import { Link } from "react-router-dom";
import RegisterForm from "./registerForm";

export default function Register() {
    var body = document.body;
    body.classList.add("backgroundCri");

    return (
            <section className="text-center text-lg-start">

                <div className="py-5 text-center">
                    <div className="container">
                        <div className="row">
                            <div className="mx-auto col-md-10 col-10 bg-white p-5">
                                <h1 className="mb-4">Crie sua conta</h1>
                                <RegisterForm />
                                <Link className="btn btn-outline-info btn-block" to="/login">Já tem uma conta?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );

}