import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {formatDa} from "../../assets/js/helper";
import IClass from "../../interfaces/IClass";
import api from "../../services/api";

export default function Class() {
    const {id} = useParams()

    const [classes, setClasses] = useState<IClass[] | any>([]);
    const [dateClass, setDateClass] = useState<IClass[] | any>([]);
    const [qtd, setQtd] = useState(0);
    const [loading, setLoading] = useState(true);

    const token = JSON.parse(localStorage.getItem('token') || "")
    const userLogado = token?.user
    const athleteLogado = token?.user.athlete?.id

    const navigate = useNavigate();


    useEffect(() => {
        // axios.get<ITest[]>('http:///api/classes')
        api.get('/api/classes/date/' + id)
            .then(resposta => {
                setClasses(resposta.data.data.classes);
                setDateClass(resposta.data.data.date);
                if (athleteLogado != undefined) {
                    api.get('/api/tests/' + resposta.data.data.date.test_id + '/getCountUserTests/' + athleteLogado)
                        .then(resposta => {
                            // console.log(resposta.data.data[0]?.countTest)
                            if (resposta.data.data.length > 0) {
                                setQtd(resposta.data.data[0]?.countTest)
                            } else {
                                setQtd(0)
                            }
                        })
                }

            }).then(resposta =>
            setLoading(false)
        )
    }, [])


    const souOhUser = (classe, station) => {
        if (!athleteLogado) {
            return false
        }
        return (athleteLogado == classe[`athleteStation${station}`]?.id)
    }

    return (
        <>
            {!loading ? (
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="mb-2">
                                    <b>Turmas <span className="text-muted"> ({formatDa(dateClass?.date || '', 'data')})</span></b>
                                </h3>
                            </div>
                        </div>

                        {qtd >= 2 ? (

                            <div className="my-4">
                              <div className='alert alert-danger' role='alert'>
                                <div className='container text-white'>
                                  <div className='alert-icon'>
                                    <i className='fa fa-bell-o fa-fw' aria-hidden='true'></i> &nbsp;
                                    <strong>Atenção!</strong> Você atingiu a quantidade máxima de inscrições.
                                    <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
                                        <span aria-hidden='true'>
                                            <i className='fa fa-times fa-fw' aria-hidden='true'></i>
                                        </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                        ) : null}
                        <div className="row my-4">

                            {/* <div className="col-md-4">
                    <div className="card"> <img className="card-img-top" src="https://static.pingendo.com/cover-moon.svg" alt="Card image cap" />
                        <div className="card-body">
                        <h4 className="card-title">Card title</h4>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> <Link to="/Class" className="btn btn-primary">Go somewhere</Link>
                        </div>
                    </div>
                </div> */}


                            {classes.map(classe =>
                                <div key={classe.id} className="col-md-4">
                                    <div className="card mb-4 box-shadow">
                                        <div className="card-header">
                                            <h4 className="my-0 font-weight-normal">{classe.title}&nbsp;</h4>
                                        </div>
                                        <div className="card-body">
                                            <h1><b>{formatDa('2022-01-01 ' + classe.time, 'hour')}</b><small
                                                className="text-muted">&nbsp; {parseInt(classe.time) > 11 ? 'PM' : 'AM'}</small>
                                            </h1>
                                            <ul className="list-unstyled mt-3 mb-4">
                                                <li className={souOhUser(classe, '1') ? 'text-danger' : ''}>1
                                                    -&nbsp;{classe['athleteStation1']?.full_name || ''}</li>
                                                <li className={souOhUser(classe, '2') ? 'text-danger' : ''}>2
                                                    -&nbsp;{classe['athleteStation2']?.full_name || ''}</li>
                                                {/* <li contenteditable="true">2 -&nbsp;<br/></li> */}
                                                <li className={souOhUser(classe, '3') ? 'text-danger' : ''}>3
                                                    -&nbsp;{classe['athleteStation3']?.full_name || ''}</li>
                                                <li className={souOhUser(classe, '4') ? 'text-danger' : ''}>4
                                                    -&nbsp;{classe['athleteStation4']?.full_name || ''}</li>
                                                <li className={souOhUser(classe, '5') ? 'text-danger' : ''}>5
                                                    -&nbsp;{classe['athleteStation5']?.full_name || ''}</li>
                                            </ul>

                                            <div className="row">

                                                <div className={`col-md-${userLogado?.role != 'athlete' && userLogado?.organizer === "1" ? 8 : 12} my-1`}>

                                                    <Link to={`/Class/${classe.id}`}
                                                          onClick={event => (classe.status == 'closed' || classe.finished || (qtd >= 2 && !(souOhUser(classe, '1') || souOhUser(classe, '2') || souOhUser(classe, '3') || souOhUser(classe, '4') || souOhUser(classe, '5')) ) ? event.preventDefault() : '')}
                                                          className={`btn btn-block ${classe.status == 'closed' || classe.finished ||  qtd >= 2 && !((souOhUser(classe, '1') || souOhUser(classe, '2') || souOhUser(classe, '3') || souOhUser(classe, '4') || souOhUser(classe, '5'))) ? 'btn-outline-secondary' : 'btn-success'}`}> Agendar</Link>
                                                </div>

                                                {userLogado?.role != 'athlete' && userLogado?.organizer === "1" ? (
                                                   <div className="col-md-4 my-1">
                                                    {classe.status == 'closed' && userLogado?.role == 'admin'? (
                                                      <Link to={`/spreadsheet/update/${classe.id}`} title="Editar resultado"
                                                            className={`btn btn-block btn-info`}>
                                                        &nbsp; <i className="fa fa-table" aria-hidden="true"></i> &nbsp;
                                                      </Link>
                                                    ) : (
                                                       <Link to={`/spreadsheet/${classe.id}`} title="Lançar resultado"
                                                             onClick={event => ((classe.status == 'closed' && userLogado?.role == 'organizer') ? event.preventDefault() : '')}
                                                             className={`btn btn-block ${(classe.status == 'closed' && userLogado?.role == 'organizer') ? 'btn-outline-secondary' : 'btn-info'} `}>
                                                         &nbsp; <i className="fa fa-table" aria-hidden="true"></i> &nbsp;
                                                       </Link>
                                                  )}
                                                   </div>
                                                ) : null}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                    </>
                )
                : (<div className="loading">
                    <span className="loader"></span>
                  </div>)
            }
            <div className="row py-4">
                <div className="col-md-12  text-right">
                    <button className="btn btn-danger btn-floating" onClick={() => navigate(`/dates/${dateClass.test_id}`)}>Voltar<br/></button>
                </div>
            </div>
        </>
    );
}
