import api from '../../services/api'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {useParams} from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import InputMask from "react-input-mask";
import { SelectTheme } from '../../components/selectTheme'

export default function FormTests() {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('token') || "").user

    let {id} = useParams()
    const [active, setActive] = useState('')
    const [status, setStatus] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [series, setSeries] = useState('')
    const [maximumInscriptions, setMaximumInscriptions] = useState('' as any)
    const [timeInterval, setTimeInterval] = useState('' as any)
    const [stage, setStage] = useState('' as any)
    const [final, setFinal] = useState('' as any)
    const [competitionId, setCompetitionId] = useState('' as any)
    const [team, setTeam] = useState('' as any)
    const [arrayTeams, setArrayTeams] = useState([] as any)

    const [dates, setDates] = useState([{ date: '' }] as any);
    // const [datas, setDatas] = useState([{data:"01/04/2023"}] as any)


    const objReg: any = {
        "id": id,
        "active": active,
        "status": status,
        "title": title,
        "description": description,
        "series": series,
        "maximum_inscriptions": maximumInscriptions,
        "time_interval": timeInterval,
        "stage": stage,
        "final": final,
        "image": "",
        "team_id": maximumInscriptions,
        "competition_id": competitionId,
        "dates": dates,
    }

    useEffect(() => {
        teams()

        if (id != undefined) {
            api.get('/api/tests/' + id)
                .then(resposta => {
                    const test = resposta.data.data

                    setActive(test?.active)
                    setStatus(test?.status)
                    setTitle(test?.title)
                    setDescription(test?.description)
                    setSeries(test?.series)
                    setMaximumInscriptions(test?.maximum_inscriptions)
                    setTimeInterval(test?.time_interval)
                    setStage(test?.stage)
                    setFinal(test?.final)
                    setTeam(test?.team_id)
                    setCompetitionId(test?.competition_id)

                    // (new Date(user.athlete?.birth_date).toLocaleString("pt-BR"))
                    setDates(test?.dates)

                    console.log(test?.dates)
                })

        }
    }, [])

    interface TimeInputsProps {
        startTime: string;
        endTime: string;
        interval: number;
    }

    const TimeInputs: React.FC<TimeInputsProps> = ({ startTime, endTime, interval }) => {
        const [timeInputs, setTimeInputs] = useState<string[]>([]);

        const generateTimeInputs = (evento: React.FormEvent<HTMLFormElement>) => {
            evento.preventDefault()

            const start = new Date(`01/01/2023 ${startTime}`);
            const end = new Date(`01/01/2023 ${endTime}`);
            const inputs: string[] = [];

            while (start <= end) {
                inputs.push(start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }));
                start.setMinutes(start.getMinutes() + interval);
            }

            setTimeInputs(inputs);
        };

        return (
            <div>
                <button >Gerar Inputs</button>
                {/*<div cx'lassName='form-row'>*/}
                <div className="form-group  ">
                    {timeInputs.map((time, index) => (
                        <input key={index} type="text" className={""} value={time} readOnly/>
                    ))}
                </div>
                {/*</div>*/}
            </div>
        );
    };

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            if (id == undefined) {
                api.post('/api/tests', objReg).then((result) => {
                    console.log(result)
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 5000
                    }).then((result) => {
                        // return navigate("/adm/tests");
                    });
                })
                    .catch(erro => {
                            console.log(erro.response)
                            Swal.fire({
                                title: 'Erro!',
                                text: erro.response.data.sqlMessage,
                                icon: 'error',
                            })
                        }
                    );

            } else {
                api.put('/api/tests/' + id, objReg).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                    }).then((result) => {
                        // return navigate("/adm/tests");
                    });
                }).catch(erro => Swal.fire({
                    title: 'Erro!',
                    text: erro.response.data.sqlMessage,
                    icon: 'error',
                }))
            }
        } catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    async function teams(value = '') {
        const response = await api.get(`/api/teams`)
        const result = await response.data
        return setArrayTeams(
            await result.data.map((club) => ({
                label: club.fantasia,
                value: club.id,
            })),
        )
    }

    const handleAddFields = () => {
        const values = [...dates];
        console.log(values[values.length-1].date)
        // new Date(values[values.length-1].date).setDate(1)
        // moment(values[values.length-1].date).add(1,'day')
        values.push({ date: '' });
        setDates(values);
    };

    const handleChange = (index, event) => {
        const values = [...dates];
        values[index].date = event.target.value;
        setDates(values);
    };

    const handleRemoveFields = (index) => {
        const values = [...dates];
        values.splice(index, 1);
        setDates(values);
    };

    // async function addDatas(ind, value ) {
    //     datas.push({data: value})
    // }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className='text-left'>

                <div className='form-row'>

                    <div className='form-group col-md-4'>
                        <label htmlFor='active'>Ativo <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Ativo', value: '1'}}
                            options={[{label: 'Ativo', value: '1'}, {label: 'Inativo', value: '0'}]}
                            value={[{label: 'Ativo', value: '1'}, {
                                label: 'Inativo',
                                value: '0',
                            }].find(option => option.value == active)}
                            name='active'
                            id='active'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setActive(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='status'>Status <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Em breve', value: 'soon'}}
                            options={[{label: 'Em breve', value: 'soon'}, {
                                label: 'Em andamento',
                                value: 'in_progress'
                            }, {label: 'Finalizada', value: 'finished'}]}
                            value={[{label: 'Em breve', value: 'soon'}, {
                                label: 'Em andamento',
                                value: 'in_progress'
                            }, {label: 'Finalizada', value: 'finished'}
                            ].find(option => option.value == status)}
                            name='status'
                            id='status'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setStatus(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                    <div className='form-group col-md-12'>
                        <label htmlFor='title'>Titulo <i className="text-danger"> *</i></label>
                        <input onChange={evento => setTitle(evento.target.value)} type='text' className='form-control'
                               value={title}
                               id='title' placeholder='Titulo'/>
                    </div>

                    <div className='form-group col-md-12'>
                        <label htmlFor='description'>Descrição <i className="text-danger"> *</i></label>
                        <input onChange={evento => setDescription(evento.target.value)} type='text'
                               className='form-control'
                               value={description}
                               id='description' placeholder='Seu description'/>
                    </div>
                </div>

                <div className='form-row'>

                    <div className='form-group col-md-4'>
                        <label htmlFor='series'>Series <i className="text-danger"> *</i></label>
                        <input onChange={evento => setSeries(evento.target.value)} type='number'
                               className='form-control'
                               value={series}
                               id='series' placeholder='setrie'/>
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='maximum_inscriptions'>Maximo de ReInscrições <i
                            className="text-danger"> *</i></label>
                        <input onChange={evento => setMaximumInscriptions(evento.target.value)} type='number'
                               className='form-control'
                               value={maximumInscriptions}
                               id='maximum_inscriptions' placeholder='Quantidade'/>
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='time_interval'>Intervalo entre as turmas <i
                            className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: '30 mim', value: '30'}}
                            options={[{label: '15 mim', value: '15'}, {label: '30 mim', value: '30'}, {
                                label: '45 mim',
                                value: '45'
                            }, {label: '1h', value: '60'}]}
                            value={[{label: '15 mim', value: '15'}, {label: '30 mim', value: '30'}, {
                                label: '45 mim',
                                value: '45'
                            }, {label: '1h', value: '60'}
                            ].find(option => option.value == timeInterval)}
                            name='time_interval'
                            id='time_interval'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setTimeInterval(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='stage'>Etapa <i className="text-danger"> *</i></label>
                        <input onChange={evento => setStage(evento.target.value)} type='text' className='form-control'
                               value={stage}
                               id='stage' placeholder='etapa'/>
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='final'>Final <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Sim', value: '1'}}
                            options={[{label: 'Sim', value: '1'}, {label: 'Não', value: '0'}]}
                            value={[{label: 'Sim', value: '1'}, {
                                label: 'Não',
                                value: '0',
                            }].find(option => option.value == final)}
                            name='final'
                            id='final'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setFinal(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='team'>Equipe <i className="text-danger"> *</i></label>

                        <Select
                            options={arrayTeams}
                            name='team'
                            id='team'
                            isDisabled={user?.role != "admin"}
                            onChange={(data: any) => {
                                setTeam(data.value)
                            }}
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            value={arrayTeams.find(option => option.value == team) || ''}
                            placeholder='Equipe'
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                </div>

                <hr/>

                <h3>Datas</h3>
                <div>
                    <div className='form-row'>
                        {dates.map((date, index) => (
                          <div className='form-group col-md-3' key={index}>
                              <label htmlFor='release_date'>Data <i className="text-danger"> *</i></label>


                              <div className="input-group ">
                                  <InputMask mask='99/99/9999'
                                             onChange={(event) => handleChange(index, event)}
                                             type='text'
                                             value={date.date_formated}
                                             className='form-control' id='release_date' placeholder='Data do lançamento'
                                  />
                                  <div className="input-group-prepend">
                                      <div className="input-group-text">
                                          &nbsp;

                                      {/*</div>*/}

                                      {dates.length >1 && dates.length == (index+1)  ?
                                        <button type="button" className="btn btn-sm btn-danger " onClick={() => handleRemoveFields(index)}>
                                            -
                                        </button>
                                        : null }

                                  </div>
                                  </div>
                              </div>

                          </div>
                        ))}
                        <div className='form-group col-md-1'>
                            <label htmlFor='release_date'> &nbsp;&nbsp;&nbsp;</label> <br/>
                            <button type="button"  className="btn btn-sm btn-success " onClick={handleAddFields}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <TimeInputs startTime="09:00 AM" endTime="5:00 PM" interval={30} />
                </div>

                <div className='row py-4'>
                    <div className='col-md-12  text-right'>
                        <button type='button' className='btn btn-danger mr-1' onClick={() => navigate(-1)}>Cancelar<br/>
                        </button>
                        <button type='submit' className='btn btn-success'> Salvar</button>
                    </div>
                </div>
            </form>
        </>

    )
}