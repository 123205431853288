import Competition from './competition/Competition'

export default function Home() {
    var body = document.body;
    body.classList.remove("backgroundCri");

    let hasError:any = localStorage.getItem('hasError');
    if (hasError){
        hasError = JSON.parse(hasError || "")
    }

    setTimeout(function(){ localStorage.removeItem('hasError')}, 500);

    return (
        <section>

            {hasError?.message ? (
                <div className="my-4">
                    <div className='alert alert-danger' role='alert'>
                        <div className='container text-white'>
                            <div className='alert-icon'>
                                <i className='fa fa-bell-o fa-fw' aria-hidden='true'></i> &nbsp;
                                {hasError.title.length > 0 ? (<strong>{hasError.title}</strong>) : null} {hasError.message}
                                <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
                                        <span aria-hidden='true'>
                                            <i className='fa fa-times fa-fw' aria-hidden='true'></i>
                                        </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="col-sm-12 text-center">
                <h4>Seja bem vindo(a) ao Campeonato Regional Interclubes top 50</h4>
            </div>
            <div className="col-sm-12">

               <Competition/>

            </div>


        </section>
);
}