import axios from "axios";
import {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

export default function Login() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [capsLockOn, setCapsLockOn] = useState(false);

    useEffect(() => {
        const inputSenha = document.getElementById("input-senha");

        const handleKeyDown = (e) => {
            if (!e.getModifierState("CapsLock")) {
                setCapsLockOn(false);
            } else {
                setCapsLockOn(true);
            }

            if (e.which === 20) {
                setCapsLockOn(prevState => !prevState);
            }
        };

        inputSenha?.addEventListener("keydown", handleKeyDown);

        return () => {
            inputSenha?.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    const handleFocusOut = () => {
        setCapsLockOn(false);
    };

    var body = document.body;
    body.classList.add("backgroundCri");

    const login = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault()

        let emailCpf = email.replaceAll( '.', '').replaceAll( '-', '') as any;
        if(emailCpf.length === 11 && !isNaN(emailCpf)){
            emailCpf = (`${emailCpf.substr(0, 3)}.${emailCpf.substr(3, 3)}.${emailCpf.substr(6, 3)}-${emailCpf.substr(9, 2)}`);
        }else{
            emailCpf = email
        }

        axios.post(`${process.env.REACT_APP_API_HOST}/api/login`,  {email: emailCpf, password: password})
            .then((result) => {
                let res = result.data;
                // console.log(res)
                localStorage.setItem('token', JSON.stringify(res));
                // return <Navigate to="" replace={true} />
                return navigate("/home");
            })
            .catch(erro => Swal.fire({
                title: 'Erro!',
                text: 'Login ou senha incorreto',
                icon: 'error',
            }))

    };
    return (
        <>
            <section className="text-center text-lg-start">

                <div className="py-5 text-center">
                    <div className="container">
                        <div className="row">
                            <div className="mx-auto col-md-6 col-10 bg-white p-5">
                                {/*<h1 className="mb-4">Login</h1>*/}
                                <img style={{maxHeight: '90px'}} className="mb-4"
                                     alt="Campeonato Interclubes" src="/images/interclubes.svg"/>

                                <form onSubmit={login}>
                                    <div className="form-group"><input type="text"
                                                                       onChange={evento => setEmail(evento.target.value)}
                                                                       className="form-control"
                                                                       placeholder="E-mail ou CPF" id="email"/></div>
                                    <div className="form-group mb-3">
                                        <div className="input-group mb-3">
                                            <input type={isPasswordVisible ? 'text' : 'password'}
                                                   onBlur={handleFocusOut}
                                                   onChange={evento => setPassword(evento.target.value)}
                                                   className="form-control input-senha" placeholder="Senha" id="input-senha"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text" onClick={togglePasswordVisibility}><i className={`fa fa-fw field-icon toggle-password ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i></span>
                                            </div>
                                        </div>
                                        {capsLockOn &&
                                            <div className="col">
                                                <span className="text-danger"><b>Caps lock está ligado</b></span>
                                            </div>
                                         }
                                        <small
                                            className="form-text text-muted text-right">
                                            <a href="/forgotPassoword"> Esqueceu a senha</a>
                                        </small>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 text-left">
                                            <Link className="btn btn-outline-primary" to="/register">Cadastre-se</Link>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <button type="submit" className="btn btn-primary ">Entrar</button>
                                            <br/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}