import { useEffect, useState } from 'react'
import api from '../../services/api'
import io from 'socket.io-client'

export default function FrameShooters() {
  const [loading, setLoading] = useState(true)

  const [frameShooters, setFrameShooters] = useState<any>([])
  const socket = io(`${process.env.REACT_APP_API_HOST}`, {
    path: '/api/socket',
  })

  useEffect(() => {
    GetFrameShooters()
    socket.on('class_closed', (dados) => {
      GetFrameShooters()
    })
  }, [])

  const toggleFullScreen = () => {
    const docElement = document.documentElement as HTMLElement

    if (
      (!document['fullscreenElement'] && !document['mozFullScreen'] && !document['webkitIsFullScreen']) ||
      (document['fullscreenElement'] === null || document['mozFullScreen'] === null)
    ) {
      if (docElement.requestFullscreen) {
        docElement.requestFullscreen()
      } else if (docElement['mozRequestFullScreen']) {
        docElement['mozRequestFullScreen']()
      } else if (docElement['webkitRequestFullScreen']) {
        docElement['webkitRequestFullScreen'](Element['ALLOW_KEYBOARD_INPUT'])
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document['mozCancelFullScreen']) {
        document['mozCancelFullScreen']()
      } else if (document['webkitExitFullscreen']) {
        document['webkitExitFullscreen']()
      }
    }
  }

  const GetFrameShooters = () => {
    setLoading(true)

    api.post('/api/frameShooters').then((response) => {
      // const data = response.data
      setFrameShooters(response.data)
    }).then(response =>
      setLoading(false),
    )
  }

  return (
    <>
      {!loading ? (
        <div className='card p-3'>
          <div className='row my-4'>
            <div className='col-sm-12 text-right'>
              <button type='button' onClick={() => toggleFullScreen()} className='btn btn-white'>
                <i className='fa fa-arrows-alt' aria-hidden='true'></i>
              </button>
            </div>

            {frameShooters.length > 0 ? (
              <>
                {frameShooters.map((test, index) =>
                  <div key={index} className='col-sm-6'>

                    <div className='col-md-12'>
                      <h3 className='mb-2 text-center'>
                        <b>{index == 0 ? 'Atirando' : 'Prepara'}  </b>
                      </h3>
                      <div className=''>
                        <b> {test.time.substr(0, 5)}</b>
                      </div>

                    </div>
                    <div className='col-md-12'>
                      <h5 className='mb-2'>
                        {test.title.substr(0, 6)} <b className='text-danger'>{test.title.substr(6, 2)}  </b>
                        {/*<b className={'text-danger'}> Atrasada</b>*/}
                      </h5>
                    </div>
                    <table className='table' style={{ borderTop: '#fff solid' }}>
                      <thead>
                      <tr>
                        <th scope='col'>&nbsp;</th>
                        <th scope='col'>Atleta</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <th scope='row'>1</th>
                        <td>{test.athleteStation1?.full_name_abv}</td>
                      </tr>
                      <tr>
                        <th scope='row'>2</th>
                        <td>{test.athleteStation2?.full_name_abv}</td>
                      </tr>
                      <tr>
                        <th scope='row'>3</th>
                        <td>{test.athleteStation3?.full_name_abv}</td>
                      </tr>
                      <tr>
                        <th scope='row'>4</th>
                        <td>{test.athleteStation4?.full_name_abv}</td>
                      </tr>
                      <tr>
                        <th scope='row'>5</th>
                        <td>{test.athleteStation5?.full_name_abv}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className='col-sm-12 text-center'>
                  <h2>Não ha turmas abertas.</h2>
                </div>
              </>
            )}

            {/*<hr />*/}

          </div>
        </div>
      ) : (<div className='loading'>
        <span className='loader'></span>
      </div>)
      }

    </>
  )
}